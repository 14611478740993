import React, { useEffect } from "react"
import Helmet from 'react-helmet';
import TextLoop from "react-text-loop";
import '../style/app.scss';

export default function Home() {

  return <div id="app">
    <Helmet>
      <meta charSet="utf-8" />
      <title>RetroHeli - A new challenging but addictive mobile game.</title>
      <meta name="description"
            content="A retro take on a familiar flappy game. It's easy! Just tap & hold to fly. Avoid the obstacles to get the high score then head online to challenge your friends! Who's the greatest RetroHeli pilot?" />
      <link rel="canonical" href="https://retroheli.digitalcake.co.nz" />
    </Helmet>

    <div className="demo">
      <video src={require('../video/web-demo-compressed.mp4')} muted loop autoPlay playsInline />
    </div>

    <div className="heading">
      <h1 className="title">
        RETROHELI
        <img src={require('../images/retro-heli.png')} />
      </h1>
      <div className="rule" />

      <div className="mobile-video">
        <video src={require('../video/web-demo-compressed.mp4')} muted loop autoPlay playsInline />
      </div>

      <div className="sub-title mobile">
        <TextLoop noWrap={false}>
          <span className="color-1">An addictive new mobile game with a retro feel</span>
          <span className="color-4">Challenge your friends to the high score</span>
          <span className="color-2">Easy to learn and hard to master</span>
          <span className="color-3">You already know the simple controls</span>
        </TextLoop>
      </div>

      <div className="sub-title desktop">
        <div>
          <span className="color-1">An addictive new mobile game with a retro feel</span>
          <span className="color-4">Challenge your friends to the high score</span>
          <span className="color-2">Easy to learn and hard to master</span>
          <span className="color-3">You already know the controls</span>
          <span className="color-5">Install now!</span>
        </div>
      </div>
      <div className="rule small" />
      <div className="app-links">
        <a href="https://apps.apple.com/us/app/id1529832562" className="link" target="_BLANK">
          <img alt="Available on the iOS App Store" src={require("../images/app-store-badge.png")} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.digitalcake.retroheli" className="link" target="_BLANK">
          <img alt="available on Google Play" src={require("../images/google-play-badge.png")} />
        </a>
      </div>
    </div>

  </div>
}
